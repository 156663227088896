import React from "react";
import {Container,Row,Col} from "react-bootstrap";
import { Link } from "gatsby";

//import fbIcon from '../images/icon-fb.png';
//import FacebookLike from './facebookLike';

const Footer = () => (
  <footer>
    <Container fluid  style={{textAlign:'left', margin:0}}>
      <Row>
        <Col>
          <ul>
            {/*
            <li><a href="https://book.housecallpro.com/book/availability/603a7c4c0f7a422fadd4d5e23713739b">Book Service Online</a></li>
            */}
            <li><a href="mailto:ben@uticashedpros.com">ben@uticashedpros.com</a></li>
            <li><a href="tel:+13154129960">(315)&nbsp;412&#8209;9960</a></li>
            {/* <li>PO Box 644</li> */}
            <li>Marcy, NY 13403</li>
          </ul>
          {/*
          <ul>
            <li><a href="https://info.uticashedpros.com/f/code">Referral Program</a></li>
          </ul>
          */}
        </Col>
        {/*
        <Col>
          <ul>
            <li><a href="https://www.facebook.com/wedocny/"><img src={fbIcon} alt='Facebook' style={{width:'50px'}}/></a></li>
            <li style={{marginTop:'0.3rem'}}>
              <FacebookLike />
            </li>
          </ul>
        </Col>
        */}
        <Col>
          <ul>
            <li><Link to="/schedule">Book Your Shed</Link></li>
            <li><a href="https://info.uticashedpros.com/f/checklist">Shed Buyer's Checklist</a></li>
            <li><a href="/photos">Photos</a></li>
            <li><Link to="/features">Features</Link></li>
            <li><a href="https://info.uticashedpros.com/f/colors">Options</a></li>
            <li><a href="/pricing">Pricing</a></li>
            {/*
            <li><a href="https://info.uticashedpros.com/blog">Blog</a></li>
            <li><Link to="/features">Features</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            */}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign:'center'}}>
          Serving Oneida County<br/>
          <div id="serviceAreaList" style={{textAlign:'left',margin:'auto',fontSize:'8pt',width:'500px' }}>
          <Row>
            <Col>
              <ul style={{textAlign:'left',listStyleType: 'circle'}}>
                <li>Utica, NY</li>
                <li>Rome, NY</li>
                <li>Floyd, NY</li>
              </ul>
            </Col>
            <Col>
              <ul style={{textAlign:'left',listStyleType: 'circle'}}>
                <li>Forestport, NY</li>
                <li>Herkimer, NY</li>
                <li>Holland Patent, NY</li>
              </ul>
            </Col>
            <Col>
              <ul style={{textAlign:'left',listStyleType: 'circle'}}>
                <li>Remsen, NY</li>
                <li>Oneida, NY</li>
                <li>Westernville, NY</li>
              </ul>
            </Col>
          </Row>
          </div>
          <Link to="/#contactForm">Contact Us</Link> For Your Area
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign:'center', color:'#aaa', fontStyle:'oblique', fontSize:'smaller', padding:'1.3rem'}}>
          <a href='https://www.congius.org'>Congius LLC</a> © {new Date().getFullYear()}
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
